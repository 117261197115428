import Footer from "../../components/Footer";
import List from "../../components/List";

function ResponsesPage() {
  return (
    <>
      <List />
      <Footer />
    </>
  );
}

export default ResponsesPage;
