import { Routes, Route } from "react-router-dom";
import ResponsesPage from "../pages/ResponsesPage";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<ResponsesPage />} />
    </Routes>
  );
};

export default AppNavigator;
