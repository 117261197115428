const base_url = "https://rsvp.toubiana.fr/api";

type Urls = {
  responsesUrl: string;
};

const urls: Urls = {
  responsesUrl: `${base_url}/responses`,
};

const headers: any = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const helpers = { urls, headers };

export default helpers;
