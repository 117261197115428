import { useEffect, useRef, useState } from "react";
import "./style.css";
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import helpers from "../../api/helpers";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
require("dayjs/locale/fr");

function List() {
  const wrapperRef = useRef(null);

  const [receptionTotal, setReceptionTotal] = useState(0);

  const grid = new Grid({
    columns: [
      { name: "Noms", width: "200px" },
      { name: "Date", width: "150px" },
      { name: "Reception", width: "100px" },
      "Petit mot",
    ],
    server: {
      url: helpers.urls.responsesUrl,
      then: (data) => {
        let receptionSum = 0;
        data.forEach((element: any) => {
          receptionSum += element.receptionCount;
        });
        setReceptionTotal(receptionSum);
        return data.map((response: any) => [
          response.name,
          dayjs(response.sentAt).locale("fr").format("DD/MM/YYYY HH:mm"),
          response.receptionCount,
          response.word,
        ]);
      },
    },
    language: {
      search: {
        placeholder: "🔍 Rechercher...",
      },
    },
    fixedHeader: true,
    search: {
      enabled: true,
    },
    sort: true,
    className: {
      th: "list-header",
      table: "table-container",
    },
  });

  useEffect(() => {
    grid.render(wrapperRef.current);
  });

  const exportData = () => {
    window.open("https://rsvp.toubiana.fr/api/responses.csv");
  };

  return (
    <div className="list-container">
      <div>
        <p>Reception: {receptionTotal.toString()}</p>
        <button className="export-button" onClick={exportData}>
          <FontAwesomeIcon icon={faDownload} />
          Export data
        </button>
      </div>
      <div ref={wrapperRef} />
    </div>
  );
}

export default List;
